import React from 'react'
import './Loader.styles.scss'

export function InitialLoader() {
  const hideFooter = window.location.search.indexOf('showFooter=false') > 0

  return (
    <div className="layout" data-testid="initial-loader">
      <div className="loader-wrapper">
        <div className="loader-content-wrapper">
          <span className="skeleton" style={{ width: '176px', height: '4px', borderRadius: '4px' }} />
          <div className="loader-logo-box">
            <span
              className="skeleton"
              style={{ width: '52px', height: '52px', transform: 'none', marginRight: '12px', borderRadius: '8px' }}
            />
            <div className="loader-title-box">
              <span className="skeleton" style={{ width: '105px', height: '16px' }} />
              <span className="skeleton" style={{ width: '65px', height: '16px' }} />
            </div>
          </div>
          <div className="loader-main">
            <span className="skeleton" style={{ height: '40px' }} />
            <div className="preset-values-skeleton">
              <span className="skeleton" />
              <span className="skeleton" />
              <span className="skeleton" />
              <span className="skeleton" />
            </div>
            <span
              className="skeleton"
              style={{
                height: '56px',
                borderRadius: '8px',
                border: '1px solid #E1E2E3',
                backgroundColor: '#fff'
              }}
            />
            <div className="info">
              <span className="skeleton" style={{ borderRadius: '50%', width: '16px', height: '16px' }} />
              <span className="skeleton" style={{ width: '92%', height: '64px' }} />
            </div>
          </div>
          <span
            className="skeleton"
            style={{ width: '100%', height: '48px', marginTop: '32px', marginBottom: '36px', borderRadius: '8px' }}
          />
        </div>
        {!hideFooter && (
          <span
            className="skeleton"
            data-testid="skeleton-footer"
            style={{ borderRadius: 'unset', height: '104px', width: '100%' }}
          />
        )}
      </div>
    </div>
  )
}
